export default function Header() {
  return (
    <>
    <header className="flex items-center justify-between mb-5 xl:flex-row xl:justify-between">
      <h1 className="font-bold uppercase tracking-wide text-4xl mb-3">
      Quotation
      </h1>
      <div>
        <img src="https://designblocks.in/img/DB.png" className="w-24" alt="Logo" />
      </div>
    </header>

    </>
  );
}
