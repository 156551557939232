import React, { useContext } from "react";
import { State } from "../context/stateContext";

export default function Footer() {
  const { name, email, website, phone, bankAccount, bankName, ifsccode } =
    useContext(State);

  return (
    <>
      <footer className="footer border-t-2 border-gray-300 pt-5 mt-10">
        <ul className="flex flex-wrap items-center justify-center">
          <li>
            <span className="font-bold">Account Name:</span> Design Blocks
          </li>
          <li>
            <span className="font-bold">Account number:</span>753601010050187
          </li>
          <li>
            <span className="font-bold">Bank Name:</span> Union bank of india
          </li>
          <li>
            <span className="font-bold">IFSC Code:</span> UBIN0810746
          </li>
          <li>
            <span className="font-bold">Email:</span> Sales@designblocks.in
          </li>
          <li>
            <span className="font-bold">Phone number:</span> 8886777989
          </li>
          <li>
            <span className="font-bold">Website:</span>{" "}
            <a href={website} target="_blank" rel="noopenner noreferrer">
            Designblocks.in
            </a>
          </li>
        </ul>
      </footer>
    </>
  );
}






// import React, { useContext } from "react";
// import { State } from "../context/stateContext";

// export default function Footer() {
//   const { name, email, website, phone, bankAccount, bankName, ifsccode } =
//     useContext(State);

//   return (
//     <>
//       <footer className="footer border-t-2 border-gray-300 pt-5">
//         <ul className="flex flex-wrap items-center justify-center">
//           <li>
//             <span className="font-bold">Account Name:</span> {name}
//           </li>
//           <li>
//             <span className="font-bold">Account number:</span> {bankAccount}
//           </li>
//           <li>
//             <span className="font-bold">Bank Name:</span> {bankName}
//           </li>
//           <li>
//             <span className="font-bold">IFSC Code:</span> {ifsccode}
//           </li>
//           <li>
//             <span className="font-bold">Email:</span> {email}
//           </li>
//           <li>
//             <span className="font-bold">Phone number:</span> {phone}
//           </li>
//           <li>
//             <span className="font-bold">Website:</span>{" "}
//             <a href={website} target="_blank" rel="noopenner noreferrer">
//               {website}
//             </a>
//           </li>
//         </ul>
//       </footer>
//     </>
//   );
// }
